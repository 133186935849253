<template>
  <SideNav v-if="user"></SideNav>
  <div id="main" class="main">
    <TopNav v-if="user"></TopNav>
    <div class="routercontent" style="padding: 10px">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>
import TopNav from "./AppComponents/TopNav.vue";
import SideNav from "./AppComponents/SideNav.vue";
import store from "@/store";
export default {
  setup() {},
  components: {
    TopNav,
    SideNav,
  },
  computed: {
    user() {
      return store.state.user;
    },
  },
};
</script>
<style>
nav {
  padding: 30px;
}

nav a {
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

@media screen and (min-width: 1200px) {
  .navbar {
    height: 40px;
  }

  #sidenav {
    width: 270px;
  }
}

@media screen and (max-width: 1200px) {
  #sidenav {
    width: 0px;
  }

  #main {
    margin-left: 0;
  }
}

.navbar {
  width: -moz-available;
  border-bottom: thin solid rgb(221, 221, 221);
}

.main {
  transition: margin-left 0.5s;
  margin-left: var(--sidenav-width);
  height: 100vh;
  margin-left: 270px;
}

.routercontent {
  transition: 00.5s;
  overflow: scroll;
  height: calc(100vh - 65px);
  padding-top: 40px;
}

.app-button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  padding: 4px;
  box-shadow: 2px 2px #e9e9e9;
  cursor: pointer;
  position: fixed;
  right: 40px;
  bottom: 40px;
}
</style>
